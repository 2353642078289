import React, { FormEvent, useRef } from "react"
import { useTranslation } from 'react-i18next';

// import { RowContainer, LabelTitle } from "./LabelTitle"

import { Button } from "../ui/button"
import { useStore } from "../../lib/states"
import { useClickAway, useToggle } from "react-use"
// import { Textarea } from "../ui/textarea"
// import { cn } from "../../lib/utils"

import { useHookStore } from "../../hook-store/store"

const DiffusionOptionPaintButton = () => {
  const [
    isProcessing,
    prompt,
    runInpainting,
    // updateSettings,
    // showPrevMask,
    // hidePrevMask,
    curLineGroup,
    lastLineGroup,
  ] = useStore((state) => [
    state.getIsProcessing(),
    state.settings.prompt,
    state.runInpainting,
    // state.updateSettings,
    // state.showPrevMask,
    // state.hidePrevMask,
    state.editorState.curLineGroup,
    state.editorState.lastLineGroup,
  ])

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  const { 
    serverProcessing,
    ipAdapterImage,
    ipAdapterScale,
    modelName,
    ipAdapterImages,
    isIopaintServerExist,
   } = (hStore as any).iopaintStore;
  const { 
    showToggleNav, 
    showInpaintSettings,
   } = (hStore as any).uiStore;

  const [showScroll, toggleShowScroll] = useToggle(false)

  const ref = useRef(null)
  useClickAway<MouseEvent>(ref, () => {
    if (ref?.current) {
      const input = ref.current as HTMLTextAreaElement
      input.blur()
    }
  })

  // const handleOnInput = (evt: FormEvent<HTMLTextAreaElement>) => {
  //   evt.preventDefault()
  //   evt.stopPropagation()
  //   const target = evt.target as HTMLTextAreaElement
  //   updateSettings({ prompt: target.value })
  // }

  const handleRepaintClick = () => {
    if (!isProcessing) {
      runInpainting(
        ipAdapterImage,
        ipAdapterScale,
        modelName,
        ipAdapterImages,
      )
    }

    (hDispatch as any)('SET_SHOWINPAINTSETTINGS', false);
  }

  // const onKeyUp = (e: React.KeyboardEvent) => {
  //   if (e.key === "Enter" && e.ctrlKey && prompt.length !== 0) {
  //     handleRepaintClick()
  //   }
  // }

  // const onMouseEnter = () => {
  //   showPrevMask()
  // }

  // const onMouseLeave = () => {
  //   hidePrevMask()
  // }

  // console.log('lineGroup', lastLineGroup, curLineGroup);
  let isMaskExist = false;

  if (lastLineGroup.length > 0 || curLineGroup.length > 0) {
    isMaskExist = true;
  }

  return (
      <div 
      // className="flex flex-col gap-1"
      >
        <div className="flex justify-between items-center pr-2">
          <div></div>
          <Button
            // size="sm"
            onClick={handleRepaintClick}
            disabled={isProcessing || serverProcessing || showToggleNav || 
              showInpaintSettings || !prompt || !isIopaintServerExist ||
              !isMaskExist
              // || curLineGroup.length === 0
            }
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
          >
            {!(serverProcessing || isProcessing) && (
              <span>Paint</span>
            )}
            {(serverProcessing || isProcessing) && (
              <span>busy...</span>
            )}
          </Button>
        </div>
      </div>
  )
}

export default DiffusionOptionPaintButton
