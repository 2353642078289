import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';

// import { RowContainer, LabelTitle } from "./LabelTitle"

// import { Button } from "../ui/button"
import { useStore } from "../../lib/states"
// import { useClickAway, useToggle } from "react-use"
// import { Textarea } from "../ui/textarea"
import { 
  generateMask, 
  srcToFile,
} from "../../lib/utils"

import { useHookStore } from "../../hook-store/store"

const DiffusionOptionsPreview = () => {
  const [
    lastLineGroup,
    // lineGroups,
    curLineGroup,
    imageWidth,
    imageHeight,
    renders,
    file,
  ] = useStore((state) => [
    state.editorState.lastLineGroup,
    // state.editorState.lineGroups,
    state.editorState.curLineGroup,
    state.imageWidth,
    state.imageHeight,
    state.editorState.renders,
    state.file,
  ])

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  // const { 
  //   serverProcessing,
  //   ipAdapterImage,
  //   ipAdapterScale,
  //   modelName,
  //   ipAdapterImages,
  //   isIopaintServerExist,
  //  } = (hStore as any).iopaintStore;
  // const { 
  //   showToggleNav, 
  //   showInpaintSettings,
  //  } = (hStore as any).uiStore;

  const [targetFile, setTargetFile] = useState(file);

  useEffect(() => {
    setTargetFileHandler();
  },[]);

   const previewHeight = 100;
   const previewWidth = previewHeight * imageWidth / imageHeight 

   const previewImageStyle = {
    height: previewHeight, 
    width: previewWidth, 
   };

   const maskPreviewStyle = {
    height: previewHeight, 
    width: previewWidth, 
    border: "0.1px dashed gray",
  };


  let previewLineGroup = curLineGroup;

  if (curLineGroup.length === 0) {
    previewLineGroup = lastLineGroup
  }

  // const maskCanvas = generateMask(imageWidth, imageHeight, lineGroups)
  const maskCanvas = generateMask(imageWidth, imageHeight, [previewLineGroup])
  const hRef = maskCanvas.toDataURL("image/jpeg");

  // console.log('state mask', maskCanvas, hRef, curLineGroup);


  const setTargetFileHandler = async () => {
    let targetFile = file
    if (file && renders.length > 0) {
      const lastRender = renders[renders.length - 1]
      targetFile = await srcToFile(lastRender.currentSrc, file.name, file.type)
      setTargetFile(targetFile);
    }
    // return targetFile
  };

  return (
      <div style={{display: "flex"}}
      // className="flex flex-col gap-1"
      >
        {file && targetFile && (
          <img 
          style={previewImageStyle}
            src={URL.createObjectURL(targetFile)}
          />
        )}
        <img 
          style={maskPreviewStyle}
          src={hRef}
        />
      </div>
  )
}

export default DiffusionOptionsPreview
