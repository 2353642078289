// import { PlayIcon } from "@radix-ui/react-icons"
import { Fragment, useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { useStore } from "../../lib/states"
import { useToast } from "../ui/use-toast"

import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import { useHookStore } from "../../hook-store/store"
import { 
  getObjectFile, 
  getOriginalFileNameFromKey,
} from '../../utils/bucket/bucket-object-util';
import { 
  IMAGEPHOTO_BACKENDURL, 
  loginPageLink,
} from "../../lib/api";

import { marks } from "../../utils/marks";

const LoadParamFile = (props) => {
  const {
    setInputFile,

    setSelectedFileUrl,
    setSelectedDisplayImage,
  } = props;

  const [
    setFile,
  ] = useStore((state) => [
    state.setFile,
  ])

  const { toast } = useToast()

  const [t] = useTranslation('translation');

  // const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [hStore, hDispatch] = useHookStore();
  const { 
    // showToggleNav, 
    // gLoading,
    isAuth,
   } = hStore.uiStore;

   const [showLoadFileModal, setShowLoadFileModal] = useState(false);
   const [loadMoadlContent, setLoadModalContent] = useState();
   const [isLoading, setIsLoading] = useState(false);

  //  useEffect(() => {

  //  }, [imageHeight, imageWidth]);

  const loginRequireContent = (
    <div>
      <div>
        {t('fileControl01' ,'Login is required for loading image file')} 
        {' '}
        from Kura Image Photo.
      </div>
      <br/>
      <div>
        <a href={loginPageLink} >
          <button>
            {marks.signInMrak} {t('fileControl02' ,'Login')}
          </button>
        </a>
      </div>
    </div>
  );

  const differentUserContent = (
    <div>
      <div>
        {t('fileControl03' ,'Login user is different. To load image, please login using same email address after logout from menu.')}
      </div>
      <br/>

      <div>
        {t('fileControl04' ,'Current Login User Email')}
        {' '}
        : {localStorage.getItem('email')}
      </div>
    </div>
  );

   useEffect(() => {

      setShowLoadFileModal(false);
      setLoadModalContent('');

      const currentUrl = new URL(window.location.href);
      const queryParams = currentUrl.searchParams;
      const key = queryParams.get('key');
      const mimeType = queryParams.get('mimeType');
      const imagePhotoUserId = queryParams.get('imagePhotoUserId');
      const pathname = window.location.pathname;

      let keyData = queryParams.get('keyData');

      if (keyData) {
        keyData = JSON.parse(keyData);
      }

      // if (!key || !mimeType || !imagePhotoUserId) {
      //   searchParams.delete('key');
      //   searchParams.delete('mimeType');
      //   searchParams.delete('imagePhotoUserId');
      //   setSearchParams(searchParams);
      //   return;
      // }

      // console.log('loadParam', key, mimeType);
      if (key && mimeType && imagePhotoUserId) {

        if (!isAuth) {
          // alert('login-is-required');
          setShowLoadFileModal(true);
          setLoadModalContent(loginRequireContent);
          
          return;
        }

        if (isAuth && imagePhotoUserId !== localStorage.getItem('userId')) {
          // alert('auth-user-is-different');
          setShowLoadFileModal(true);
          setLoadModalContent(differentUserContent);

          return;
        }

        getObjectFileHandler(key, mimeType, pathname);

        searchParams.delete('key');
        searchParams.delete('mimeType');
        searchParams.delete('imagePhotoUserId');
        setSearchParams(searchParams);
        // window.history.push(window.location.pathname + "?" + currentUrlParams.toString());
      }


      if (keyData && imagePhotoUserId) {

        if (!isAuth) {
          // alert('login-is-required');
          setShowLoadFileModal(true);
          setLoadModalContent(loginRequireContent);
          
          return;
        }

        if (isAuth && imagePhotoUserId !== localStorage.getItem('userId')) {
          // alert('auth-user-is-different');
          setShowLoadFileModal(true);
          setLoadModalContent(differentUserContent);

          return;
        }

        getKeyDataFileHandler(keyData);

        // searchParams.delete('key');
        // searchParams.delete('mimeType');
        // searchParams.delete('imagePhotoUserId');
        // searchParams.delete('openApp');
        // searchParams.delete('keyData');
        // setSearchParams(searchParams);
        // window.history.push(window.location.pathname + "?" + currentUrlParams.toString());
      }
    

   },[isAuth]);


   const getObjectFileHandler = async (
      key, 
      mimeType, 
      pathname,
    ) => {
      try {

        setIsLoading(true);

        const resData = await getObjectFile(
          IMAGEPHOTO_BACKENDURL,
          localStorage.getItem('token'),
          localStorage.getItem('userId') + '-image-photo',
          key,
          mimeType,
        );

        if (resData) {
          console.log(resData);

          const originalName = getOriginalFileNameFromKey(key);

          const file = new File([resData], originalName, {type: mimeType});
          // console.log('loadparam file', file);
          
          if (pathname === '/') {
            setFile(file);
          }

          if (pathname === '/illustration-generator') {
            setInputFile(file);
          }

          if (pathname === '/background-remover') {
            const fileUrl = URL.createObjectURL(file);
            setSelectedFileUrl(fileUrl);
            setSelectedDisplayImage(fileUrl);
          }

          // return file;
        }

        setIsLoading(false);

      } catch(err) {
        console.log(err);
        setIsLoading(false);
        throw err;
      }
    };


    const getKeyDataFileHandler = async (keyData) => {
      try {

        setIsLoading(true);

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const openApp = queryParams.get('openApp');

        // const imageKey = keyData.key;
        // const ipaImageKeys = keyData.ipaImageKeys;
        const imageKey = keyData.image.key;
        const ipaImages = keyData.ipaImages;
        const fileData = {
          imageFile: null,
          ipaImageFiles: [],
          openApp: openApp,
        };

        // console.log('keyData', keyData);

        const resData = await getObjectFile(
          IMAGEPHOTO_BACKENDURL,
          localStorage.getItem('token'),
          localStorage.getItem('userId') + '-image-photo',
          imageKey,
          "image/jpeg",
        );

        if (resData) {
          console.log(resData);

          const originalName = getOriginalFileNameFromKey(imageKey);
          

          let fileMimeType = keyData.image.mimeType;
          
          if (!fileMimeType) {
            fileMimeType = 'image/jpeg'

            const lowerKey = imageKey.toLowerCase();
            const ext = lowerKey.split('.').pop();

            if (ext === 'png') {
              fileMimeType = 'image/png'
            }
          }

          const file = new File([resData], originalName, {type: fileMimeType});
          // console.log('loadparam file', file);
          
          if (file) {
            fileData.imageFile = file;

            for (const ipaImage of ipaImages) {
              const resData = await getObjectFile(
                IMAGEPHOTO_BACKENDURL,
                localStorage.getItem('token'),
                localStorage.getItem('userId') + '-image-photo',
                ipaImage.key,
                "image/jpeg",
              );
      
              if (resData) {
                console.log(resData);
      
                const originalName = getOriginalFileNameFromKey(ipaImage.key);
                
                let fileMimeType = ipaImage.mimeType;

                if (!fileMimeType) {
                  fileMimeType = 'image/jpeg';

                  const lowerKey = ipaImage.key.toLowerCase();
                  const ext = lowerKey.split('.').pop();
                  
                  if (ext === 'png') {
                    fileMimeType = 'image/png'
                  }
                }
      
                const file = new File([resData], originalName, {type: fileMimeType});

                if (file) {
                  fileData.ipaImageFiles.push(file);
                }
              }
            }

            hDispatch('SET_LOADEDFILES', fileData);
            
            setFile(fileData.imageFile);
            //// open target app modal

            searchParams.delete('key');
            searchParams.delete('mimeType');
            searchParams.delete('imagePhotoUserId');
            searchParams.delete('openApp');
            searchParams.delete('keyData');
            setSearchParams(searchParams);

          } 

        } else {
          throw new Error('error-occured')
        }

        setIsLoading(false);

      } catch(err) {
        console.log(err);
        setIsLoading(false);
        throw err;
      }
    };


  return (
    <Fragment>
      {showLoadFileModal && (
        <Modal
          onClose={() => {
            setShowLoadFileModal(false);
          }}
        >
          <div>
            {loadMoadlContent}
          </div>
        </Modal>
      )}
      {isLoading && (
        <Loader />
      )}
    </Fragment>
  )
}

export default LoadParamFile;
