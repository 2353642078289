import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


// import { useStore } from '../../hook-store/store';
import { useHookStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';
import classes from './UploadImage.module.css';

const UploadImageNameChange = (props) => {
  const { 
    currentRenderFile,
    createCurrentRenderFile,
  } = props

  const [t] = useTranslation('translation')

  const [store, dispatch] = useHookStore();
  const { gLoading, isAuth } = store.uiStore;

  const [fileNameInput, setFileNameInput] = useState('');
  const [showNameInput, setShowNameInput] = useState(false);
  const [inputError, setInputError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   if (uploadNameFile) {
  //     const fileSplit = uploadNameFile.name.split('.');
  //     const fileExt = fileSplit.pop();
      
  //     console.log(fileSplit)
  //     const nameForInput = fileSplit.join(".");
      
  //     setFileNameInput(nameForInput);
  //   }
  // },[uploadNameFile])

  const createUpdateNameHandler = (event) => {
    setInputError('');
    const input = event.target.value.trim();
    
    console.log(input.length);
    if (!input.length) {
      setInputError('name is required');
      return;
    }
    if (input.length > 100) {
      setInputError('maximum name length is 100');
      return;
    }

    if (input.includes('/')) {
      setInputError('name should not have "/"');
      return;
    }

    setFileNameInput(input);
  };

  const updateFileName = (file, fileNameInput) => {

    if (!fileNameInput || !file) {
      return;
    }

    createCurrentRenderFile(file, fileNameInput);

    // const fileSplit = file.name.split('.');
    // const fileExt = fileSplit.pop();

    // const fileName = fileNameInput + '.' + fileExt;

    // // console.log(name);
    // // throw new Error('error-error');

    // const uploadFile = new File([file], fileName, {type: file.type});
    // setUploadNameFile(uploadFile);
    // console.log(uploadFile);
    // return uploadFile;
  };


  let fileExt = '';

  if (currentRenderFile.name) {
    const fileSplit = currentRenderFile.name.split('.');
    fileExt = fileSplit.pop();
  }

  let filesUploadNameChangeBody

  filesUploadNameChangeBody = (
    <div className={classes.uploadImageSection}>
      <div className={classes.uploadImageSection}>
        <span 
          onClick={() => {
            setShowNameInput(!showNameInput);
          }}
        >
          {t('fileUploadAction01', 'Change file name')} {marks.triangleDown}
        </span>
      </div>
      {showNameInput && (
        <div>
          <div className={classes.uploadImageSection}>
            <div>
              <label>{t('fileUploadAction02', 'name input')}
                {' '}({t('fileUploadAction03', 'max 100 characters')})</label>
            </div>
            <input className='inputBase'
              style={{maxWidth: "50%"}}
              type="text"
              // placeholder='enter file name here'
              // value={fileNameInput}
              onChange={createUpdateNameHandler}
            />
            <span>.{fileExt}</span>
            
            <div>{inputError}</div>
          </div>

        
          <div className={classes.uploadImageSection}>
            <button className='iopaintBtnBase'
              disabled={isLoading || inputError}
              onClick={() => { 
                updateFileName(currentRenderFile, fileNameInput);
              }}
            >
              {t('fileUploadAction01', 'Change file name')}
            </button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Fragment>
      <div>{filesUploadNameChangeBody}</div>
    </Fragment>
  )
}

export default UploadImageNameChange;
