import { useState, useRef, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useHookStore } from '../../hook-store/store';

// import { IMAGEPHOTO_BACKENDURL, kuraImagePhotoUrl } from '../../lib/api';

import IpaInputImage from '../../image/for-iopaint/image-prompt/boy-man-h200.png';
import IpaIpaImage from '../../image/for-iopaint/image-prompt/statue-h200.png';
import IpaResultImage from '../../image/for-iopaint/image-prompt/boy-man-ipa2-h200.png';
import IpaMaskImage from '../../image/for-iopaint/image-prompt/boy-man-ipa2-mask-h200.png';

// import { marks } from '../../utils/marks';
import classes from './AboutIopaint.module.css';



function AboutIopaintImagePrompt(props) {
  const { } = props;

  const [t] = useTranslation('translation');

  const bgRemoveRef = useRef(null);
  const illustGeneratorRef = useRef(null);

  const [hStore, hDispatch] = useHookStore();
  // console.log('store-BucketObjects.js', store);

  const [showControlNetDesc, setShowControlNetDesc] = useState(false);

  const [showBgRemoveDetail, setShowBgRemoveDetail] = useState(false);
  const [showIllustDetail, setShowIllustDetail] = useState(false);
  // useEffect(() => {
  //   if (gLoading) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[gLoading]);

  const scrollToBgRemove = () => {
    bgRemoveRef.current.scrollIntoView();
    setShowBgRemoveDetail(true);
  };

  const scrollToIllustGenerator = () => {
    illustGeneratorRef.current.scrollIntoView();
    setShowIllustDetail(true);
  };



  

  

  let aboutIopaintImagePromptBody;

  aboutIopaintImagePromptBody = (
    <div>
      {/* <div>
        <strong>
          image-prompt-examples-and-desc
        </strong>
      </div> */}
      <br/>
      <div>
        {t('aboutPage.18', 'It is possible to use image with text prompt for inpainting')}
        {' '}(image prompt).
        <br/>
        <br/>
        In the following example, statue image is used as image prompt, and "a handsome man" is used as text prompt.
      </div>
      <br/>
      <div>
        <div className={classes.aboutIopaintImageContainer}>
          <div>
            <img className={classes.aboutIopaintImage}
              src={IpaInputImage}
            />
            <div>{t('aboutPage.13', 'Input image')}</div>
          </div>
          <div>
            <img className={classes.aboutIopaintImage}
              src={IpaIpaImage}
            />
            <div>Image prompt</div>
          </div>
        </div>
        <br/>

        <div className={classes.aboutIopaintImageContainer}>
          <div>
            <img className={classes.aboutIopaintImage}
              src={IpaMaskImage}
            />
            <div>{t('aboutPage.14', 'Selected part')}</div>
          </div>
          <div>
            <img className={classes.aboutIopaintImage}
              src={IpaResultImage}
            />
            <div>
              {t('aboutPage.15', 'Generated image')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )


  
  return (
    <Fragment>
      {aboutIopaintImagePromptBody}
    </Fragment>
  );
}

export default AboutIopaintImagePrompt;