import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ImagePhotoAuthInfo from '../UploadImage/ImagePhotoAuthInfo';
import Loader from '../Loader/Loader';
import { useHookStore } from '../../hook-store/store';
import * as uploadUtils from '../../utils/bucket/bucket-upload-util';


import { IMAGEPHOTO_BACKENDURL } from '../../lib/api';
import { marks } from '../../utils/marks';
// import classes from './BgRemoveUpload.module.css';

const FilesUploadAction = (props) => {
  const { 
    // showDrawReultModal, 
    // setShowDrawResultModal, 
    uploadFiles,
    uploadApp,
  } = props

  const [t] = useTranslation('translation')

  const [store, dispatch] = useHookStore();
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    // selectedPrefix,
  } = store.bucketObjectStore;
  const { gLoading, isAuth } = store.uiStore;
  const { serviceBucketsDataList } = store.serviceBucketsStore;

  const [imagePhotoBucketData, setImagePhotoBucketData] = useState();
  const [resultMessage, setResultMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  let canUpload = false;

  if (isAuth && imagePhotoBucketData && uploadFiles) {
    canUpload = true;
  }

  // console.log('bucketName', bucketName);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {

    const kuraImagePhotoBucketData = serviceBucketsDataList.find(element => {
      return element.bucket.endsWith('-image-photo');
    });
  
    let bucketName = '';
    
    if (kuraImagePhotoBucketData) {
      //@ts-ignore
      dispatch('SET_BUCKETNAME', kuraImagePhotoBucketData.bucket);
      setImagePhotoBucketData(kuraImagePhotoBucketData);
    }

  },[serviceBucketsDataList]);


  const createFileName = (file, uploadApp) => {
    let name;

    if (uploadApp === 'illustrationGenerator') {
      name = `illustration-${Date.now()}.${file.type.split('/')[1]}`;
    }

    if (uploadApp === 'backgroundRemover') {
      name = `bgRemove-${Date.now()}.${file.type.split('/')[1]}`;
    }

    return name;
  };


  const drawImagesUploadHandler = async (path, files, uploadApp) => {
    try {
      // setIsLoading(true);
      setResultMessage('');

      //@ts-ignore
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileUpload: null,
      };


      // if (!file) {
      //   throw new Error('error occured');
      // }

      const file = files[0]

      // const name = file.name.replace(/(\.[\w\d_-]+)$/i, `-dream-${Date.now()}`)
      // const name = createFileName(file, uploadApp);
      // const curRender = renders[renders.length - 1];
      // const currentRenderFile = await getCurrentRender(storeRenders);
      const uploadFile = new File([file], file.name, {type: file.type});
      // const uploadFile = new File([currentRenderFile], fileName, {type: currentRenderFile.type});

      // const uploadFile = await uploadFileCreate(curRender.currentSrc, name, file.type);
      
      // const files = [uploadFile];
      // console.log('file, files', file, files);
      // console.log(currentRenderFile);
      console.log(path, files);
      // throw new Error('error-error');

      if (!files || files.length === 0) {
        throw new Error('upload file is required');
      }

      if (files.length > 10) {
        throw new Error('Upload limit is 10 file');
      }

      let uploadSizeGB = 0;
      // let limitGB = 0.1;

      for (const file of files) {
        uploadSizeGB = uploadSizeGB + file.size / 10**9;
      }

      // console.log('uploadSizeGB', uploadSizeGB, totalSizeGB);

      // const activeSubscription = subscriptions.find(subscription => {
      //   return subscription.status === 'active';
      // });

      // if (activeSubscription) {
      //   limitGB = parseInt(activeSubscription.metadata.storeSizeGB);
      // }
      
      if ((totalSizeGB + uploadSizeGB) > limitGB) {
        throw new Error('Total file sizes are more than limit');
      }


      // console.log(hasActiveSubscription, hasActiveSubscription2);


      let result;


      result = await uploadUtils.s3fileUpload2(
        IMAGEPHOTO_BACKENDURL,
        localStorage.getItem('token'),
        path,
        [uploadFile],
        bucketName,
        dispatch,
        uploadApp,
      );

      console.log(result);
      
      resultsForAction.fileUpload = result.data;

      // setSelectedFiles(null);

      // const addedList = topLevels.concat(result.data.data);
      
      //@ts-ignore
      // dispatch('SET_TOPLEVELS', addedList);


      // //@ts-ignore
      // dispatch('SET_OBJECTACTION', {
      //   actionType: 'file-upload',
      //   delete: false,
      //   add: true,
      //   result: resultsForAction,
      // });

      // //@ts-ignore
      // dispatch('SET_DATAFORMETADATA', result.data.dataForMetadata);

      //@ts-ignore
      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'upload success',
        message: '',
      });

      setResultMessage('upload success');

      setTimeout(() => {
        //@ts-ignore
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      // showUploadMoadlHandler(false);
      // setIsLoading(false);
      //@ts-ignore
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      // setSelectedFiles([]);
      // setDroppedFiles([]);

      //// set isUploadFailed to get services-info again
      
      // //@ts-ignore
      // dispatch('SET_ISUPLOADFAILED', true);

      //@ts-ignore
      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `upload failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        //@ts-ignore
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setResultMessage('upload failed');
      
      // setIsLoading(false);
      //@ts-ignore
      dispatch('SET_GLOADING', false);
    }

  };


  let drawResultActionBody

  drawResultActionBody = (
    <div>
      {/* <button className='btnBase'
        onClick={drawImageDownloadHandler}
      >
        {t('guideImage11', 'Download')}
      </button> */}
      {/* {uploadFiles?.length > 0 && (
        <div>
          <img
            src={URL.createObjectURL(uploadFiles[0])}
          />
        </div>
      )} */}

      {canUpload && (
        <div>
          <div>
            <button className='iopaintBtnBase'
              style={{fontWeight: "bold", fontSize: "1.5rem", padding: "0.75rem"}}
              disabled={isLoading}
              onClick={() => { 
                drawImagesUploadHandler(
                  '', 
                  uploadFiles, 
                  uploadApp,
                );
              }}
            >
              {/* {t('guideImage12', 'Upload')} */}
              {t('uploadAction01', 'upload')} {marks.uploadMark}
            </button>
          </div>
        </div>
      )}
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {resultMessage && (
        <div>
          <strong>{resultMessage}</strong>
        </div>
      )}

      {!canUpload && (
        <div>
          <ImagePhotoAuthInfo />
        </div>
      )}
    </div>
  )

  return (
    <Fragment>
      <div>{drawResultActionBody}</div>
    </Fragment>
  )
}

export default FilesUploadAction;
