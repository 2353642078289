import { Fragment, useEffect, useState } from 'react'
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';

// import './App.css'

// import Loader from '../Loader/Loader';
import IllustrationGeneratorUpload from './IllustrationGeneratorUpload';

import { useHookStore } from '../../hook-store/store';

// import { IMAGEPHOTO_BACKENDURL } from '../../lib/api';
import { marks } from '../../utils/marks';
import './IllustrationGenerator.css';


function IllustrationGeneratorResult(props) {

  const { 
    outputFile,
    setOutputFile,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();

  const [fileDimension, setFileDimension] = useState({ height: 0, width: 0 });
  const [showFullSize, setShowFullSize] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    setShowFullSize(false);
  },[outputFile]);

  const illustDownloadHandler = () => {
    download(
      outputFile, 
      `illustration-${Date.now()}.jpg`, 
      'image/jpeg'
    );
  };

  const getImgDimension = (event) => {
    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
    
    if (event.target.naturalHeight !== fileDimension.height ||
        event.target.naturalWidth !== fileDimension.width
    ) {

        // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

        setFileDimension({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
  }

  let outImageStyle = {
    maxHeight: "400px",
    maxWidth: "100%",
  }

  if (showFullSize && fileDimension.width > 0) {
    outImageStyle = {
      width: `${fileDimension.width}px`,
      height: `${fileDimension.height}px`,
    };
  }
  
  return (
    <Fragment>
      {outputFile && (
        
        <div>
          <br/>
          <div className='illustGeneratorSection'>
            <div>
              <span>
              {t('illustGenerator05', 'output image')}
              </span>
              {' '}
              <span
                onClick={() => { setShowFullSize(!showFullSize) }}
              >
                ({t('illustGenerator06', 'Display full size')})
              </span>  
            </div>
            <div style={outImageStyle}>
              <img 
                // className='illustGeneratorImage'
                style={outImageStyle}
                src={URL.createObjectURL(outputFile)}
                onLoad={getImgDimension}
              />
            </div>
            {/* <div>{JSON.stringify(fileDimension)}</div> */}

          </div>
          <div className='illustGeneratorSection'>
            <div className='illustGeneratorButtons'>
              <button 
                className="iopaintBtnBase" 
                onClick={illustDownloadHandler}
              >
                {t('illustGenerator07', 'download file')} {marks.downloadMark}
              </button>
              <button className="iopaintBtnBase" 
                onClick={() => { setShowUploadModal(true) }}
              >
                {t('illustGenerator08', 'upload file')} {marks.uploadMark}
              </button>
            </div>
          </div>
        </div>
      )}

      {showUploadModal && (
        <IllustrationGeneratorUpload 
          outputFile={outputFile}
          setOutputFile={setOutputFile}
          setShowUploadModal={setShowUploadModal}
        />
      )}
    </Fragment>
  )
}

export default IllustrationGeneratorResult;
