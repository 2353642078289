import { Fragment, useEffect, useRef, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useStore } from "../../lib/states"; 

import Loader from '../Loader/Loader';
import BgRemoveHeader from '../BackgroundRemove/BgRemoveHeader/BgRemoveHeader';
import IllustrationGeneratorAbout from './IllustrationGeneratorAbout';
import IllustrationGeneratorResult from './IllustrationGeneratorReslut';
import IllustrationGeneratorSocket from './IllustrationGeneratorSocket';
import LoadParamFile from '../FileControl/LoadParmFile';

import { useHookStore } from '../../hook-store/store';
import { resizeImageFile } from '../../utils/image/image-utils';

import { IMAGEPHOTO_BACKENDURL } from '../../lib/api';

import { marks } from '../../utils/marks';
import './IllustrationGenerator.css';

function IllustrationGenerator(props) {

  const [t] = useTranslation('translation');

  const hiddenFileInput = useRef(null);

  let [searchParams, setSearchParams] = useSearchParams();

  const [file] = useStore((state) => [state.file]);

  const [hStore, hDispatch] = useHookStore();
  const {
    illustUserRunning,
    upscaleServerProcessing,
  } = hStore.illustrationGeneratorStore;
  const { currentInpaintFile } = hStore.iopaintStore;

  const [inputFile, setInputFile] = useState();
  const [outputFile, setOutputFile] = useState();
  
  const [fileDimension, setFileDimension] = useState({ height: 0, width: 0 });
  const [isServerExist, setIsServerExist] = useState(true);
  const [showIllustAbout, setShowIllustAbout] = useState(false);
  const [iopaintFileLoaded, setIopaintFileLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  


  useEffect(() => {
    const fetchIllustrationApi = async () => {
      try {
        const result = await fetch(IMAGEPHOTO_BACKENDURL + '/image-upscaler/test-get');
        
        const resData = await result.json();

        if (result.status !== 200) {
          throw new Error('fetch server failed');
        }

        if (result.status === 200) {
          setIsServerExist(true);
          // hDispatch('CLEAR_NOTIFICATION');

        } else {
          setIsServerExist(false);
          throw new Error('fetch server failed');
        }

        // console.log(result, resData);

      } catch(err) {
        console.log('fetch server err', err);
        setIsServerExist(false);

        // hDispatch('SHOW_NOTIFICATION', {
        //   status: 'error',
        //   title: 'Server is down now, Please wait until server is recovered.',
        //   message: '',
        // });
      }
    }

    fetchIllustrationApi();

    const fetchServerInterval = setInterval(() => {
      fetchIllustrationApi();
    }, 1000*5);

    return () => {
      clearInterval(fetchServerInterval);
    }

  },[]);


  useEffect(() => {
    console.log(fileDimension);
    if (fileDimension.width > 0 && inputFile) {
      if (fileDimension.width > 500 || fileDimension.height > 500) {
        inputFileResizeHandler(inputFile);
      }
    }
  },[fileDimension]);

  useEffect(() => {
    if (!iopaintFileLoaded) {
      loadIopaintFileHandler();
    }
  },[]);


  const handleInputClick = event => {
    hiddenFileInput.current.click();
  };

  const setInputFileHandler = (event) => {
    if (event.target.files[0]) {
      setInputFile(event.target.files[0]);
    }
  }



  const upscaleHandler = async (
    url, 
    token, 
    selectedFiles, 
    uploadApp,
    upscaleType,
    magnification,
) => {
    try {
      setIsLoading(true);
      hDispatch('SET_ILLUSTUSERRUNNING', true);

      const data = new FormData(); 

      // console.log(selectedFiles);
      for (const file of selectedFiles) {
        data.append('files', file);
      }
      // data.append('files', selectedFiles);
  
      // data.append('storePath', path);
      // data.append('bucket', bucketName);
      
      if (localStorage.getItem('customerId')) {
        data.append('customerId', localStorage.getItem('customerId'));
      }
      
      if (uploadApp) {
        data.append('uploadApp', uploadApp);
      }

      if (upscaleType) {
        data.append('upscaleType', upscaleType);
      }

      if (magnification) {
        data.append('magnification', magnification);
      }
  
      // console.log('data', data, data.getAll('files'));
      const headers = {
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      };
    
      // axios.post(url + "/bucket/object", data, { 
      // axios.post(url + "/bucket/objects", data, {  
      //   headers: headers
      //   // receive two    parameter endpoint url ,form data
      // })

      const result = await axios.request({
        method: 'post',
        url: url + "/image-upscaler",
        data: data,
        headers: headers,
        onUploadProgress: (p) => {
          // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 
  
          let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;
  
          if (p.loaded/p.total === 1) {
            message = 'Uploaded, please wait until storing files finish...'
          }
  
          // dispatch('SHOW_NOTIFICATION', {
          //   status: '',
          //   title: message,
          //   message: '',
          // });
        }
      });

      console.log(result);

      if (result.data?.data) {
        const upscaleFile = await base64ToFileHandler(result.data.data);
        if (upscaleFile) {
          setOutputFile(upscaleFile);
        }
        // setUpscaleB64(result.data.data);
      }

      setIsLoading(false);

      setTimeout(() => {
        hDispatch('SET_ILLUSTUSERRUNNING', false);
      },1000*15)

  } catch(err) {
      console.log(err);
      setIsLoading(false);
      hDispatch('SET_ILLUSTUSERRUNNING', false);

      throw err;
    };
  }


  const base64ToFileHandler = async (b64) => {
    try {
      const fileData = b64.split(";")[0];
      const fileType = fileData.split(":")[1];
      const fileName = `upscale-${Date.now()}.${fileType.split("/")[1]}`;

      const result = await fetch(b64);
      const resData = await result.blob();
      const file = new File([resData], fileName, { type: fileType });
    //   console.log(file);

      return file;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };


  const getImgDimension = (event) => {
    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
    
    if (event.target.naturalHeight !== fileDimension.height ||
        event.target.naturalWidth !== fileDimension.width
    ) {

        // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

        setFileDimension({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
  }


  const inputFileResizeHandler = async (file) => {
    try { 
      const ext = file.type.split('/')[1];

      const resFile = await resizeImageFile(
        file, 
        'file', 
        ext,
        500,
        500,
      );
      console.log(resFile);

      if (resFile) {
        setInputFile(resFile);
      }


    } catch(err) {
      console.log(err);
      throw err;
    }
  }

  const loadIopaintFileHandler = () => {
    const inpaintParam = searchParams.get('inpaintFile');
    
    if (iopaintFileLoaded) {
      return;
    }
    // console.log(inpaintParam, currentInpaintFile)
    if (inpaintParam === 'inpaint' && currentInpaintFile) {
      setInputFile(currentInpaintFile);
      setIopaintFileLoaded(true);
    }

    if (inpaintParam === 'input' && file) {
      setInputFile(file);
      setIopaintFileLoaded(true);
    }
  };



  let upscalerAvailable = true;

  if (!isServerExist || illustUserRunning || upscaleServerProcessing) {
    upscalerAvailable = false;
  }


  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-dream-inpainter.spaceeight.net-1713343913172"
        style={{width:"302px", height:"67px", border:"none"}}    
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-dream-inpainter.spaceeight.net-1713343913172"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-dream-inpainter.spaceeight.net-1713343935915"
        style={{width:"452px", height:"92px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-dream-inpainter.spaceeight.net-1713343935915"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-dream-inpainter.spaceeight.net-1713343954996"
        style={{width:"602px", height:"132px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-dream-inpainter.spaceeight.net-1713343954996"
      />
    );
  }


  return (
    <Fragment>
      <BgRemoveHeader />

      <div className='illustGenerator'>

        <div 
          style={{display: 'flex', justifyContent: "center", paddingBottom: "2rem" }}
        >
          {bannerElement}
        </div>
        
        <div className='illustGeneratorSection'>
          <div className='illustGeneratorInfoMark'>
            <span className='illustGeneratorInfoMarkButton'
              onClick={() => { setShowIllustAbout(true); }}
            >
              {marks.infoCircleMark} {t('illustGenerator01', 'Info')}
            </span>
          </div>
        </div>
        <div className='illustGeneratorSection'>
          <div className='illustGeneratorTitle'>

            Illustration generator & upscaler
          </div>

        </div>
      
        <div>
          <div className='illustGeneratorSection'>
            <input 
              id='fileinput'
              ref={hiddenFileInput}
              style={{display: 'none'}}
              type="file"
              onChange={setInputFileHandler}
              // onChange={createInputFileUrl}
              disabled={isLoading}
              accept="image/png, image/jpeg, image/webp"
            />
            <button 
              className="iopaintBtnBase" 
              disabled={isLoading}
              onClick={handleInputClick}
            >
              {t('illustGenerator02', 'Select image file for illustration')}
              {/* {marks.televisionMark} {t('resizer01', 'Select device images (max 10 files)')} */}
            </button>
          </div>
         

        {inputFile && (
          <div>
            <div className='illustGeneratorSection'>
            {t('illustGenerator03', 'input image')}
              <img className='illustGeneratorImage'
                // style={{maxWidth: "100%"}}
                src={URL.createObjectURL(inputFile)}
                onLoad={getImgDimension}
              />
                {/* <div>{JSON.stringify(fileDimension)}</div> */}
            </div>

            <div className='illustGeneratorSection'>
              <button 
                className="iopaintBtnBase"
                style={{fontWeight:"bold", padding: "1rem"}}
                disabled={isLoading || !upscalerAvailable}
                onClick={() => {
                  // createIllustrationHandler(inputUrl); 
                  upscaleHandler(
                    IMAGEPHOTO_BACKENDURL,
                    localStorage.getItem('token'),
                    [inputFile],
                    '',
                    'illustration'
                  );
                }}
              >
                {upscalerAvailable && (
                  <span>{t('illustGenerator04', 'Generate Illustration')}</span>
                )}
                {!upscalerAvailable && (
                  <span>busy...</span>
                )}
              </button>
            </div>
          
          </div>
        )}

        {isLoading && (
          <Loader />
        )}
        </div>

      {outputFile && (
        <IllustrationGeneratorResult 
          outputFile={outputFile}
          setOutputFile={setOutputFile}
          isLoading={isLoading}
        />
      )}
      </div>

      {showIllustAbout && (
        <IllustrationGeneratorAbout 
          setShowIllustAbout={setShowIllustAbout}
        />
      )}

      <IllustrationGeneratorSocket />

      <LoadParamFile 
        setInputFile={setInputFile}
      />
    </Fragment>
  )
}

export default IllustrationGenerator;
